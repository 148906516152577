/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Image, Text, Subtitle, Title } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"reference"}>
        <Column className="pb--10 pl--25 pr--25 pt--10" name={"prazdna_sekcee"} layout={"l1"}>
          
          <ColumnWrap className="column__flex --center el--1 --full mt--10 pb--10" columns={"1"} fullscreen={true}>
            
            <ColumnWrapper className="pb--25" style={{"paddingBottom":null}}>
              
              <Image className="swp-T00-mobile --right" src={"https://cdn.swbpg.com/o/2124/8e308098c208425aac7816f98e602e91.svg"} svg={false} sizes="100vw" style={{"maxWidth":45}} srcSet={""} RootClassName={"column__pic"}>
              </Image>

              <Text className="swp-T00-mobile text-box text-box--right mt--02" style={{"maxWidth":497,"marginTop":null}} content={"Jestli potřebujete pomoct, v pravým horním rohu naleznete nápovědu"}>
              </Text>

              <Text className="text-box text-box--left" style={{"maxWidth":488}} content={"Sekci smažete otevřením editačního panelu kliknutím na tlačítko „Sekce“ a následným kliknutím na tlačítko „Smazat“"}>
              </Text>

              <Subtitle className="subtitle-box mt--25" style={{"marginTop":null}} content={"<span style=\"color: rgb(142, 146, 172);\">Tohle je prozatím jediná sekce vašeho webu.</span>"}>
              </Subtitle>

              <Title className="title-box mt--06" style={{"maxWidth":620,"marginTop":null}} content={"Začněte tím, že tuhle sekci smažete a přidáte si sekci vlastní."}>
              </Title>

              <Image className="--right mt--40" src={"https://cdn.swbpg.com/o/2124/0939ff1d9f92402f8a460fa8a39064fd.svg"} svg={false} sizes="100vw" style={{"maxWidth":45,"marginTop":null}} srcSet={""} RootClassName={"column__pic"}>
              </Image>

              <Text className="text-box text-box--right mt--02" style={{"maxWidth":429,"marginTop":null}} content={"Pro přidání nové sekce klikněte na \"+\"<br>tam, kde chcete sekci přidat."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pt--06" style={{"backgroundColor":"rgba(245,100,30,1)"}} layout={"l1"}>
          
          <ColumnWrap className="column__flex --justify el--3" style={{"paddingBottom":0}} columns={"3"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--20 ls--0" content={"<span style=\"color: rgb(255, 255, 255); font-weight: normal;\">MEP Design &amp; Consulting s.r.o.</span>"}>
              </Title>

              <Text className="text-box fs--14" style={{"maxWidth":334}} content={"<span style=\"color: rgb(255, 255, 255); font-weight: normal;\">Poličanská 1487,&nbsp;190 16 Praha 9<br>IČ: 14091500, DIČ CZ14091500<br></span><span style=\"color: rgb(255, 255, 255); font-weight: normal;\">C 360464 vedená u Městského soudu v Praze<br></span><span style=\"color: rgb(255, 255, 255); font-weight: normal;\">datová schránka: jd528g5<br></span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":445}}>
              
              <Text className="text-box text-box--center" style={{"maxWidth":269}} content={"<span style=\"color: rgb(255, 255, 255); font-weight: normal;\">+ 420 774 520 238</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":522}}>
              
              <Text className="text-box" style={{"maxWidth":563}} content={"<span style=\"caret-color: rgb(255, 255, 255); color: rgb(255, 255, 255);\">M</span><span style=\"caret-color: rgb(255, 255, 255); color: rgb(255, 255, 255); font-weight: normal;\">echanical,&nbsp;</span><span style=\"caret-color: rgb(255, 255, 255); color: rgb(255, 255, 255);\">E</span><span style=\"caret-color: rgb(255, 255, 255); color: rgb(255, 255, 255);\"><span style=\"font-weight: normal;\">lectrical &nbsp;and&nbsp;&nbsp;<br></span>P</span><span style=\"caret-color: rgb(255, 255, 255); color: rgb(255, 255, 255); font-weight: normal;\">lumbing&nbsp;Engineering</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}